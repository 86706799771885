import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const Api = {
    findAdminUserPage: (data) => AppHttpKit.postJSON(`/v1/sysUser/findAdminUserPage`,data),// 查询列表
    queryInfomationById: (data) => AppHttpKit.get(`/v1/sysUser/adminUserDetail/${data.userNo}`,data), // 详情
    updateAdminUser: (data) => AppHttpKit.postJSON(`/v1/sysUser/updateAdminUser`,data), // 编辑
    deleteAdminUser: (data) => AppHttpKit.postJSON(`/v1/sysUser/deleteAdminUser`,data), // 删除运营管理员
    saveAdminUser: (data) => AppHttpKit.postJSON(`/v1/sysUser/saveAdminUser`,data), // 创建运营管理员
    
}
export default Api;