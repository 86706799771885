import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const SysUserApi = {
    adminUserDetail: (userNo) => AppHttpKit.get(`/v1/sysUser/adminUserDetail/${userNo}`,userNo), // 查询用户详情
    roleList:(data) => AppHttpKit.get(`/v1/role/findRoleSelect`,data), // 角色列表
    findCompanySelect: (data) => AppHttpKit.get(`/v1/findCompanySelect`,data), // 获取企业下拉框

}
export default SysUserApi;