<template>
  <div v-loading="loading">
    <el-form label-position="right" ref="form" label-width="160px" :model="user" :rules="rules">
      <el-form-item label="姓名：" prop="realName">
        <el-col :span="8">
          <el-input v-model="user.realName" maxlength="15" placeholder="请输入"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="手机号:" prop="username" class="waring">
        <el-col :span="8">
          <el-input :disabled="upte" v-model="user.username" placeholder="请输入" maxlength="11"></el-input>
          <span class="text color">&nbsp;注意：本手机号用于出租车公司登录管理平台</span>
        </el-col>
      </el-form-item>
      <el-form-item label="角色名称：" prop="roleId">
        <el-col :span="8">
          <el-select v-model="user.roleId" @change="roleChange" placeholder="请选择角色">
            <el-option v-for="item in roleList" :key="item.no" :label="item.name" :value="item.no">
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="公司名称：" prop="companyNo" v-if="showCompanySelect">
        <el-col :span="8">
          <el-select v-model="user.companyNo" @change="companyChange" placeholder="请选择公司"
            :disabled="!canEditCompanySelect">
            <el-option v-for="item in CompanyList" :key="item.no" :label="item.name" :value="item.no">
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="车队名称：" prop="motorcadeNo" v-if="showmotorcadeSelect">
        <el-col :span="8">
          <el-select v-model="user.motorcadeNo" @change="$forceUpdate()" placeholder="请选择车队">
            <el-option v-for="item in motorcadeList" :key="item.no" :label="item.name" :value="item.no">
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item class="center">
        <el-button type="primary" @click="submitForm">保存</el-button>
        <span style="padding: 0 20px"></span>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import admin from "@/api/admin";
import basePage from "../../base/basePage.vue"
import { navTo } from "@/utils/NavigatorUtils";
import { mapMutations } from "vuex";
import SysUserApi from "@/api/SysUserApi";
import tripMotorCadeApi from "@/api/TripMotorCadeApi"
export default {
  extends: basePage,
  data() {
    return {
      loading: false,
      roleList: [],
      CompanyList: [],
      user: {
        realName: "",
        username: "",
        roleId: "",
        companyNo: "",
        motorcadeNo: "",
      },
      disabled: false,
      originCompanyNo: "",
      showCompanySelect: true,
      showmotorcadeSelect: false,
      canEditCompanySelect: true,
      motorcadeList: [],
      upte: false,
      rules: {
        realName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 2,
            max: 15,
            message: "长度在 2 到 15 个字符",
            trigger: "blur",
          },
        ],
        username: [
          { required: true, validator: this.validPhone, trigger: "blur" },
        ],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }],
        companyNo: [
          { required: true, message: "请选择公司", trigger: "change" },
        ],
        motorcadeNo: [
          { required: true, message: "请选择车队", trigger: "change" },
        ]
      },
    };
  },
  async created() {
    await this.getRoleList();
    this.getCompanyList();
    if (this.isAdmin) {
      this.canEditCompanySelect = true;
      this.user.companyNo = this.$store.state.user.companyNo;
    }
    if (this.$route.query.no) {
      this.getUserDetail();
      this.upte = true;
      this.canEditCompanySelect = true;
    }
  },
  methods: {
    ...mapMutations({
      close: "mutationCloseTag",
    }),
    getUserDetail() {
      this.loading = true;
      SysUserApi.adminUserDetail(this.$route.query.no).then((resp) => {
        if (resp.code == "200") {
          this.user = resp.data;
          this.originCompanyNo = resp.data.companyNo;
          this.loading = false;
          // console.log(this.roleList,'2',this.user);
          let type = this.roleList.filter((p) => p.no == this.user.roleId)[0]
            .type;
          this.showCompanySelect = !["admin", "traffic_bureau", "customer"].includes(type);
          this.showmotorcadeSelect = type == 'motorcade';
          if (this.showmotorcadeSelect) {
            this.findMotorcadeList();
          }
        }
      });
    },
    async getRoleList() {
      let resp = await SysUserApi.roleList();
      if (resp.code == "200") {
        this.roleList = resp.data;
        console.log(this.roleList);
      }
    },
    getCompanyList() {
      SysUserApi.findCompanySelect().then((resp) => {
        if (resp.code == "200") {
          this.CompanyList = resp.data;
        }
      });
    },
    cancel() {
      this.close({
        path: "/saveAdmin",
      });
      navTo(this, "/adminPage");
    },
    validPhone(rule, value, callback) {
      const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;

      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //this.$confirmDialog("确定要进行当前操作吗?", () => {
          this.loading = true;
          this.user.no ? this.updateUserById() : this.addUser();
          //});
        } else {
          return false;
        }
      });
    },
    addUser() {
      admin.saveAdminUser(this.user).then((resp) => {
        if (resp.code == "200") {
          this.$successMsg(resp.msg);
          this.cancel();
        } else {
          this.$errorMsg(resp.msg);
        }
        this.loading = false;
      });
    },
    updateUserById() {
      admin.updateAdminUser(this.user).then((resp) => {
        if (resp.code == "200") {
          this.user = {};
          this.$successMsg(resp.msg);
          this.cancel();
        } else {
          this.$errorMsg(resp.msg);
        }
        this.loading = false;
      });
    },
    roleChange(no) {
      this.$forceUpdate();
      let type = this.roleList.filter((p) => p.no == no)[0].type;
      this.showCompanySelect = !["admin", "traffic_bureau", "customer"].includes(type);

      this.showmotorcadeSelect = type == 'motorcade' && this.user.companyNo != "";
      if (!this.isPlatform) {
        this.user.companyNo = this.showCompanySelect ? this.originCompanyNo : "";
        this.canEditCompanySelect = true;
      } else {
        this.canEditCompanySelect = false;
      }
      this.companyChange();
    },
    companyChange() {
      this.$forceUpdate();
      let type = this.roleList.filter((p) => p.no == this.user.roleId)[0].type;
      this.showmotorcadeSelect = type == 'motorcade' && this.user.companyNo != "";
      if (this.showmotorcadeSelect) {
        this.user.motorcadeNo = "";
        this.findMotorcadeList();
      }
    },
    findMotorcadeList() {
      if (!this.user.companyNo) {
        return;
      }
      tripMotorCadeApi.findMotorCadeSelect(this.user.companyNo).then((resp) => {
        if (resp.code == "200") {
          console.log(resp);
          this.motorcadeList = resp.data;
        } else {
          this.$errorMsg(resp.msg);
        }
        this.loading = false;
      });
    }
  },
};
</script>


<style lang="scss" scoped>
.waring {
  position: relative;

  .text {
    position: absolute;
    margin-left: 10px;
    color: #ccc;
    font-size: 12px;
  }

  .color {
    color: red;
  }
}

.switchStyle /deep/.el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
  font-size: 12px;
}

.switchStyle /deep/.el-switch__label--left {
  z-index: 9;
  left: 18px;
}

.switchStyle /deep/.el-switch__label--right {
  z-index: 9;
  left: -6px;
}

.switchStyle /deep/.el-switch__label.is-active {
  display: block;
}

.switchStyle.el-switch /deep/.el-switch__core,
.el-switch /deep/.el-switch__label {
  width: 50px !important;
}

.center {
  text-align: center;
  margin-top: 60px;
}
</style>
