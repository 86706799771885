import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const TripMotorCadeApi = {
    search: (data) => AppHttpKit.postJSON(`/v1/tripMotorCadelist`,data),//这里必须写清楚接口的注释说明
    saveTripMotorCade: (data) => AppHttpKit.postJSON(`/v1/saveTripMotorCade`,data),
    queryTripMotorCadeById: (data) => AppHttpKit.postJSON(`/v1/queryTripMotorCadeById/`,data),
    updateTripMotorCade: (data) => AppHttpKit.postJSON(`/v1/updateTripMotorCade/`,data),
    deleteTripMotorCade: (data) => AppHttpKit.postJSON(`/v1/deleteTripMotorCade/`,data),
    batchDeleteTripMotorCade:(data) => AppHttpKit.postJSON(`/v1/batchDeleteTripMotorCade/`,data),
    findMotorCadeSelect:(data)=>AppHttpKit.get(`/v1/findMotorCadeSelect/${data}`),//获取车队下拉框
}
export default TripMotorCadeApi;
